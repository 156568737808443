<template>
    <div class="alert alert-warning" role="alert">
        <h1>Això no pinta gens bé</h1>
        <p class="h3">
            Com a responsables de trencar el CANTIC poseu-vos en contacte
            amb l'administrador &#x1F9D0; per a que us pugui posar la sanció corresponent.
        </p>
    </div>
</template>
<style scoped>
.alert {
    margin-top: 2em;
}
</style>