<template>
    <nav aria-label="...">
        <ul class="pagination">
            <li class="page-item" :class="active == 1 ? 'disabled' : ''">
                <a class="page-link" href="#" @click="goPage(true, -1)" alt="Anterior"><strong>&#x1f844;</strong></a>
            </li>
            
            <li class="page-item" v-if="numberOfPages > 1"><a class="page-link" href="#"  @click="goPage(false, 1)">Primera</a></li>
            <li class="page-item" v-if="pageIndex[0] != 1">&#x2026;</li>
            <li class="page-item " v-for="p in pageIndex" :key="p" :class="active == p ? 'active': ''">
                <a class="page-link" href="#" @click="goPage(false, p)">{{ p }} </a>
            </li>
            <li class="page-item" v-if="pageIndex[4] != numberOfPages && numberOfPages > 5">&#x2026;</li>
            <li class="page-item" v-if="numberOfPages > 1"><a class="page-link" href="#" @click="goPage(false, numberOfPages)">Última</a></li>
            <li class="page-item" :class="page == numberOfPages ? 'disabled' : ''">
                <a class="page-link" href="#" @click="goPage(true, 1)" alt="Següent"><strong>&#x1f846;</strong></a>
            </li>
        </ul>
    </nav>
</template>
<script>
export default {
    name: 'Paginacio',
    props: {
        total: Number,
        page: Number,
        perPage: Number
    },
    data() {
        return {
            pageIndex: [],
            active: Number,
            numberOfPages: 0,
        }
    },
    created() {
        this.numberOfPages = Math.ceil(this.$store.state.total / this.$store.state.perPage);
        this.active = parseInt(this.$store.state.page);
        this.createPageIndex();
    },
    methods: {
        goPage: function(relative, page) {
            if (relative) this.active += page;
            else this.active = page;
            if (this.active < 1) this.active = 1;
            this.$emit('page-changed', this.active);
            this.createPageIndex();
        },
        createPageIndex: function() {
            this.pageIndex = [];
            if(this.numberOfPages > 5) {
                if(this.active <= 3) {
                    for(let i = 1; i <= 5; i++) {
                        this.pageIndex.push(i);
                    }
                }
                else if(this.active > this.numberOfPages - 3) {
                    for(let i = this.numberOfPages - 4; i <= this.numberOfPages; i++) {
                        this.pageIndex.push(i);
                    }
                }
                else {
                    for(let i = this.active - 2; i <= this.active + 2; i++) {
                        this.pageIndex.push(i);
                    }
                }
            }
            else {
                for(let i = 1; i <= this.numberOfPages; i++) {
                    this.pageIndex.push(i);
                }
            }
        },

    },
    watch: {
        page: function() {
            this.active = this.page;
            this.goPage(false, this.active);
        },
        perPage: function() {
            this.numberOfPages = Math.ceil(this.$store.state.total / this.$store.state.perPage);
            if (this.active > this.numberOfPages)
                this.active = this.numberOfPages
            this.createPageIndex();
        },
        total: function() {
            this.numberOfPages = Math.ceil(this.$store.state.total / this.$store.state.perPage);
            this.createPageIndex();
        }
    }
}
</script>