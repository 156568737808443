import { createStore } from 'vuex'

export default createStore({
  state: {
    total: 0,
    offset: 0,
    perPage: 15,
    page: 1,
    scope: 'np',
    searchMethod: 1,
    text: ''
  },
  mutations: {
    setTotal(state, value) {
      state.total = value;
    },
    setOffset(state, value) {
      state.offset = value;
    },
    serPerPage(state, value) {
      state.perPage = value
    },
    setPage(state, value) {
      state.page = value;
    },
    setScope(state, value) {
      state.scope = value;
    },
    setSearchMethod(state, value) {
      state.searchMethod = value;
    },
    setText(state, value) {
      state.text = value;
    },
  },
  actions: {
  },
  modules: {
  }
})
