<template>
    <h1>Què és</h1>
    <div class="content">
        <p><i>El Catàleg d’autoritats de noms i títols de Catalunya (CANTIC)</i> és un catàleg d’autoritats cooperatiu que es realitza en el Catàleg Col·lectiu de les Universitats de Catalunya (CCUC) i està liderat per la Biblioteca de Catalunya.</p>
        <p>El seu objectiu és normalitzar els punts d’accés dels catàlegs bibliogràfics, millorar la comunicació entre els diferents catàlegs i sobretot afavorir la cerca i la recuperació de la informació.</p>


        <p>El seu abast són registres d’autoritats dels tipus següents: </p>
        <ul>
            <li>Noms de persones i de famílies</li>
            <li>Noms d’entitat corporativa</li> 
            <li>Noms de congrés</li>
            <li>Noms de geogràfics jurisdiccionals</li>
            <li>Títols preferits</li>
            <li>Combinacions de nom-títol, quan una entrada principal, secundària, secundària de matèria o secundària de col·lecció sota un nom de persona, d’entitat o de congrés inclou un títol preferit</li>
        </ul>


        <p>S’exclouen els registres d’autoritats següents:</p>
        <ul>
            <li>Encapçalaments temàtics </li>
            <li>Noms geogràfics no jurisdiccionals</li> 
            <li>Termes de gènere/forma </li>
            <li>Noms amb subdivisió de matèria </li>

        </ul>

        <p>CANTIC dóna un tracte especial a les autoritats de nom i títol vinculades a la cultura catalana. Aquestes autoritats reben un treball d’autoritat complet i proporcionen, en molts casos, accés a la informació de <i>l’Enciclopèdia</i> (Enciclopèdia Catalana SAU).</p>
        <p>Es considera autoritat d’àmbit català: </p>
        <ul>
            <li>Persones, famílies i/o entitats nascudes i/o residents a Catalunya,</li>
            <li>persones, famílies i/o entitats que utilitzin la llengua catalana com a mitjà d’expressió,</li> 
            <li>qualsevol, família persona i/o entitat d’especial interès per a la cultura catalana,</li>
            <li>autoritats que segons RDA s’han d’establir a partir de les obres de referència catalanes, per exemple: autors clàssics grecs i llatins, etc.</li>
            <li>títols vinculats a autoritats d’àmbit català, per exemple: títols de col·leccions catalanes, obres clàssiques anònimes escrites en llengua catalana, etc.</li>
        </ul>
    
    </div>
</template>