<template>
    
<h1>Política de tractament de dades personals</h1>
<br>
<p>D’acord amb l’art. 13 del Reglament (UE) 2016/679 del Parlament Europeu i del Consell, de 27 d'abril de 2016 relatiu a la protecció de les persones físiques pel que fa al tractament de dades personals i a la lliure circulació d'aquestes dades, s’informa:</p>
<br>
<p><strong>Responsable del tractament:</strong> Biblioteca de Catalunya, amb domicili al carrer Hospital, 56, 08001 Barcelona, telèfon +34 93 270 23 00, bustia@bnc.cat, http://www.bnc.cat.</p>
<br>
<p><strong>Finalitat del tractament:</strong> L’objectiu del catàleg d’autoritats és normalitzar les diferents formes de nom d’un agent. En el cas d’una persona física es recullen un seguit de dades personals on regeix el principi de minimització de dades. Els camps més habituals són: forma de nom acceptada, variants del nom, data i lloc de naixement i professió. Tanmateix pot incloure altres tipus de dades personals que la persona ha fet manifestament públiques, com, per exemple, un lloc associat a la residència, correu electrònic, telèfon, fax, àrea d’especialització actual o antiga, grup associat (afiliació) i informació sobre la família (membres destacats de la família, títol hereditari, etc.).</p>
<br>
<p><strong>Temps de conservació:</strong> Les dades seran conservades indefinidament amb finalitats d'arxiu d’interès públic.</p>
<br>
<p><strong>Legitimació del tractament:</strong> La Biblioteca de Catalunya té com a funció supervisar, validar i unificar el catàleg d’autoritats de Catalunya, d’acord amb el que disposa l’article 9.2 de la Llei 4/1993, de 18 de març, del sistema bibliotecari de Catalunya.</p>
<br>
<p><strong>Destinataris de cessions o transferències:</strong></p>
<p>Les dades es troben disponibles a la ciutadania via web i es poden cedir a institucions d’àmbit català i a proveïdors de serveis de la Biblioteca. S’ha previst la transferència internacional de dades a:</p>
<br>
<ul>
    <li><a href="http://viaf.org" target="_blank">VIAF (Virtual International Authority File)</a>, catàleg d’autoritats conjunt de diverses biblioteques nacionals del món gestionat per l'Online Computer Library Center (OCLC).</li>
    <li><a href="http://http://thesaurus.cerl.org/cgi-bin/search.pl" target="_blank">CERL Thesaurus</a>, catàleg gestionat pel Consortium of European Research Libraries que inclou diversos catàlegs d’autoritats d’àmbit europeu.</li>
</ul>
<br>
<p><strong>Drets de les persones interessades:</strong> Es poden exercir els drets d’accés, rectificació, supressió i portabilitat de les dades, i la limitació o oposició al seu tractament mitjançant un correu electrònic a snb@bnc.cat.</p>
<br>
<p>Si es considera que el tractament de les dades personals infringeix la normativa es té dret a presentar una reclamació davant d’una autoritat competent. A Catalunya, <a href="https://apdcat.gencat.cat/ca/contacte/" target="_blank">Autoritat Catalana de Protecció de Dades</a>, carrer Rosselló, 214, Esc. A, 1r 1a, 08008 Barcelona, telèfon +34 93.552.78.00 </p>
<br>
<p>Per a informació addicional consulteu l’apartat <a href="http://www.bnc.cat/rgpd" target="_blank">protecció de dades</a> de la web de la Biblioteca de Catalunya.</p>

</template>