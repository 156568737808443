<template>
<h1>Preguntes freqüents</h1>

<div class="content">
<ul>
<li><a href="#1">Quines són les fonts emprades?</a></li>
<li><a href="#2">Quina és la normativa aplicada?</a></li>
<li><a href="#4">Què és la Comissió Assessora de Catalogació?</a></li>
<li><a href="#5">Què és el número de registre?</a></li>
</ul>

<a name="1"></a><p class="h3">Quines són les fonts emprades?</p>
<p>Normalment es cita el document catalogat que motiva la creació del punt d’accés. Tanmateix també es tenen en compte catàlegs d’autoritats de referència com el de la Biblioteca de Catalunya (LENOTI), el de la Biblioteca Nacional d’Espanya i el de la Library of Congress, o obres de referència com la l’Enciclopèdia Catalana. (vegeu també l’apèndix B del <i>Manual del CANTIC</i> sota informació tècnica).</p>

<a name="2"></a><p class="h3">Quina és la normativa aplicada?</p>
<p>Les regles de catalogació utilitzades per a la tria i la forma dels encapçalaments de nom, nom-títol i títol són les <i>RDA: Recursos, descripció i accés</i>. El format d'autoritats emprat és el <a href="http://www.bnc.cat/sprof/normalitzacio/marc21a/asumari.htm" target="_blank"><i>Format MARC 21 concís per a dades d'autoritat</i></a>.</p>

<a name="4"></a><p class="h3">Què és la Comissió Assessora de Catalogació?</p>
<p>El 20 de novembre de 1998 es creà la Comissió Assessora de Catalogació com a òrgan consultiu de la Biblioteca de Catalunya en matèria de catalogació. Les funcions d’aquesta Comissió són: estudiar l’adaptació de la normativa catalogràfica internacional al sistema bibliotecari de Catalunya; proposar l’ordre de prioritats en l'adaptació d'aquesta normativa; coordinar les actuacions de les entitats representades en entorns bibliotecaris de fora de Catalunya. Per tal d’acomplir les seves funcions, la Comissió crea subcomissions tècniques.<br>
El 25 d’octubre de 2005 acorda crear el catàleg d’autoritats de Catalunya per normalitzar els punts d’accés dels catàlegs bibliogràfics, millorar la comunicació entre els diferents catàlegs i sobretot afavorir la cerca i la recuperació de la informació.</p>

<a name="5"></a><p class="h3">Què és el número de registre?</p>
<p>Cada registre d’autoritat està identificat per un número proporcionat pel sistema que els gestiona. Els registres d’autoritat CANTIC són creats i gestionats en el Catàleg Col·lectiu de les Universitats de Catalunya (CCUC), el número de registre correspon al número de control proporcionat pel sistema Alma (ID MMS). Aquest número proporciona un accés directe al registre. A efectes d’intercanvi es recomana mantenir aquest número (camp 001 de MARC 21) en els registres copiats o descarregats ISO 2709.</p>

</div>
</template>

<style scoped>
p {
    margin: 2rem 0;
}
</style>