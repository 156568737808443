<template>
<div>
    <h1>Registre d'autoritat</h1>
    <ul class="nav nav-tabs" role="tablist">
        <li class="nav-item" role="presentation">
            <a data-bs-target="#fitxa" id="tab1" class="nav-link active" data-bs-toggle="tab" role="tab">Fitxa</a>
        </li>
        <li class="nav-item" role="presentation">
            <a data-bs-target="#marc" id="tab2" class="nav-link" data-bs-toggle="tab" role="tab">Marc 21</a>
        </li>
        <li class="nav-item" role="presentation">
            <a data-bs-target="#iso" id="tab3" class="nav-link" data-bs-toggle="tab" role="tab">Iso 2709</a>
        </li>
    </ul>
    <div class="tab-content" id="tabContent">
        <div id="fitxa" class="tab-pane fade show active" role="tabpanel">
            <Fitxa :record="record" v-if="notEmpty" :key="record.id" />
        </div>
        <div id="marc" class="tab-pane fade" role="tabpanel">
            <Marc :record="record" v-if="notEmpty" :key="record.id" />
        </div>
        <div id="iso" class="tab-pane fade" role="tabpanel">
            <Iso :record="record" v-if="notEmpty" :key="record.id" />
        </div>
    </div>
    <br />
    <div class="row">
        <div class="col-4" v-if="$store.state.searchMethod == 1">
            <router-link :to="{name: 'detall',  params: getPrev }" v-if="notEmptyPrev"><button class="btn btn-primary">&#x1f844; Anterior</button></router-link>
            &nbsp;
            <router-link :to="{name: 'detall',  params: getNext }" v-if="notEmptyNext"><button class="btn btn-primary">Següent &#x1f846;</button></router-link>
        </div>
        <div class="col-4"><router-link :to="{name: 'index'}" ><button class="float-right btn btn-primary">&#x1f845; Tornar</button></router-link></div>
        <div class="col-4"><a :href="linkCCUC()" target="_blank"><button class="float-right btn btn-info">Bibliogràfics al CCUC</button></a></div>
    </div>
</div>
</template>
<script>
import Fitxa from '../components/Fitxa';
import Marc from '../components/Marc';
import Iso from '../components/Iso';
export default {
    components: { Fitxa, Marc, Iso },
    data() {
        return {
            index: Number,
            recordId: Number,
            record: Object,
            next: Object,
            prev: Object
        }
    },
    created() {
        this.index = this.$route.params.index;
        this.recordId = this.$route.params.recordId;
        this.getRecord();
    },
    beforeRouteUpdate(to, _, next) {
        this.recordId = to.params.recordId;
        this.index = to.params.index;
        this.getRecord();
        next();
    },
    methods: {
        getRecord: function() {
            this.axios.get(`${process.env.VUE_APP_API_URL}/get_record/${this.recordId}`, { "headers": { "authorization": `bearer ${process.env.VUE_APP_API_KEY}` } } )
                .then( (res) => {
                    this.record = res.data.record;
                    if (this.index > 0 && this.$store.state.searchMethod == 1)
                        this.getNextPrev();
                })
                .catch( (err) => {
                    console.log('Impossible recuperar el registre:', err)
                });
        },
        getNextPrev: async function () {
            this.axios.get(`${process.env.VUE_APP_API_URL}/next_prev/${this.index}/${this.$store.state.scope}`, { "headers": { "authorization": `bearer ${process.env.VUE_APP_API_KEY}` } } )
                .then( (res) => {
                    this.next = res.data.next;
                    this.prev = res.data.prev;
                })
                .catch ( (err) => {
                    console.log("Error obtenint veins:", err);
                });
        },
        linkCCUC: function () {
            let encap = "*";
            if (this.record.etiquetes !== undefined) {
                for (let field of this.record.etiquetes) {
                    if (field.etiqueta.startsWith("1")) {
                        encap = encodeURI(field.contingut);
                        break;
                    }
                }
            }
            
            
            return `https://csuc-network.primo.exlibrisgroup.com/discovery/search?query=creator,exact,${encap}&tab=DiscoveryNetwork&search_scope=DiscoveryNetwork&sortby=rank&vid=34CSUC_NETWORK:CCUC_UNION`
        }
    },
    computed: {
        notEmpty: function() {
            if(Object.keys(this.record).length === 0)
                return false;
            else
                return true;
        },
        notEmptyPrev: function() {
            if(Object.keys(this.prev).length === 0)
                return false;
            else
                return true;
        },
        notEmptyNext: function() {
            if(Object.keys(this.next).length === 0)
                return false;
            else
                return true;
        },
        getNext: function() {
            return { recordId: this.next.id_registre, index: this.next.id }
        },
        getPrev: function() {
            return { recordId: this.prev.id_registre, index: this.prev.id }
        },
        
    }
    
}
</script>

<style scoped>
.nav-tabs {
    margin-bottom: 1em;
}
</style>