import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';
import VueAxios from 'vue-axios';
import VueGtag from "vue-gtag";
import 'bootstrap';

import './assets/cantic.scss';

//createApp(App).use(store).use(router).use(VueAxios, axios).use(VueGtag, {config: {id: "UA-28417044-3"}}, router).mount('#app');
createApp(App).use(store).use(router).use(VueAxios, axios).use(VueGtag, {config: {id: "G-TV17PFFK8F"}}, router).mount('#app');
// 