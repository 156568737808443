<template>
    <div></div>
</template>
<script>
export default {
    created() {
        let mms_id = this.$route.params.mms_id;
        this.axios.get(`${process.env.VUE_APP_API_URL}/record_by_mmsid/${mms_id}`, { "headers": { "authorization": `bearer ${process.env.VUE_APP_API_KEY}` } } )
            .then( data => {
                console.log(data.data.record.id_registre);
                this.$router.push({ path: `/detall/${data.data.record.id_registre}/0`})
            })
            .catch( (err) => {
                if(err.response) {
                    if (err.response.status == 404)
                        this.$router.push({ name: 'noregistre' });
                    else
                        this.$router.push({ name: 'granerror' });
                } else
                    this.$router.push({ name: 'granerror' });
            });
    }
}
</script>