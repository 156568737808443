import { createRouter, createWebHistory } from 'vue-router';
import Index from '../views/Index';
import Detall from '../views/Detall';
import Quees from '../views/Quees';
import Participants from '../views/Participants';
import Pmf from '../views/Pmf';
import Infotec from '../views/Infotec';
import Protec from '../views/Protec';
import Access from '../views/Accessibilitat';
import Esdevenir from '../views/Esdevenir';
import Enviat from '../views/Enviat';
import Altrescat from '../views/Altrescat';
import Registre from '../views/Registre';
import OldRegistre from '../views/OldRegistre';
import Noregistre from '../views/Noregistre';
import Granerror from '../views/Granerror';
import Notrobat from '../views/Notrobat';
import Video from '../views/Video';

const routes = [
  {
    path: '/',
    name: 'index',
    component: Index
  },
  {
    path: '/detall/:recordId/:index',
    name: 'detall',
    component: Detall
  },
  {
    path: '/quees',
    name: 'quees',
    component: Quees
  },
  {
    path: '/participants',
    name: 'participants',
    component: Participants
  },
  {
    path: '/pmf',
    name: 'pmf',
    component: Pmf
  },
  {
    path: '/infotec',
    name: 'infotec',
    component: Infotec
  },
  {
    path: '/protec',
    name: 'protec',
    component: Protec
  },
  {
    path: '/access',
    name: 'access',
    component: Access
  },
  {
    path: '/esdevenir',
    name: 'esdevenir',
    component: Esdevenir
  },
  {
    path: '/enviat',
    name: 'enviat',
    component: Enviat
  },
  {
    path: '/altrescat',
    name: 'altrescat',
    component: Altrescat
  },
  {
    path: "/registre/:mms_id",
    component: Registre,
  },
  {
    path: "/registres/CUCId/:id",
    component: OldRegistre
  },
  {
    path: "/noregistre",
    name: "noregistre",
    component: Noregistre,
  },
  {
    path: "/granerror",
    name: "granerror",
    component: Granerror,
  },
  {
    path: "/video/:video/:title",
    name: "video",
    component: Video,
  },
  {
    path: "/:catchAll(.*)",
    component: Notrobat,
  },
  
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router;
