<template>
    <div class="alert alert-warning" role="alert">
        <h1>Ooops! Sembla que ens hem perdut!</h1>
        <p class="h3">No entreu en pànic, torneu enrere i torneu a provar &#x1F609;</p>
    </div>
</template>
<style scoped>
.alert {
    margin-top: 2em;
}
</style>