<template>
    <hr class="style-five">
    <div class="container impuls">
        <div class="part">
            <span class="h6">Projecte impulsat per:</span>
            <div class="impuls logos">
                <div><a href="http://www.bnc.cat"><img src="../assets/logo_bc_std_transparent.svg" alt="Biblioteca de Catalunya"></a></div>
                <div><a href="https://www.csuc.cat/"><img src="../assets/csuc.svg" alt="CSUC"></a></div>
            </div>
        </div>
        <div class="part">
            <span class="h6">Amb la col·laboració de:</span>
            <div class="impuls logos">
                <div><a href="https://www.enciclopedia.cat/"><img src="../assets/ec.svg" alt="Enciclopèdia catalana"></a></div>
            </div>
            
        </div>
        
    </div>
    <footer class="page-footer blue font-small pt-4">
        <div class="container-fluid text-center text-md-bottom">
            <div class="row">
                <!--<div class="col-md-3 mt-md-0 mt-3"><a href="http://www.bnc.cat" target="_blank"><img src="../assets/logo_bc_std_transparent.svg" alt="Biblioteca de Catalunya" class="img-peu"></a></div>-->
                <div class="col-md-12 mt-md-0 mt-3">(c) 2021 Biblioteca de Catalunya. Carrer de l'Hospital, 56. 08001 Barcelona.Tlf.:+34 93 270 23 00.</div>
                <!--<div class="col-md-3 mb-md-0 mb-3"><img src="../assets/logo_dept_std_transparent.svg" alt="Departament de Cultura" class="img-peu"></div>-->
            </div>
        </div>
    </footer>
</template>

<script>
//import { defineComponent } from '@vue/composition-api'

export default ({
    name: 'Peu'
})
</script>

<style scoped>
.img-peu {
    height: 50px;
}
.impuls {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}
.part {
    width: 50%;
    
}

.logos {
    height: 50px;
    margin-top: 1em;
}
.logos img {
    height: 40px;
    margin-right: 20px;
}

hr.style-five {
    border: 0;
    height: 0; /* Firefox... */
    box-shadow: 0 0 7px 1px rgb(5, 61, 8);
}
hr.style-five:after {  /* Not really supposed to work, but does */
    content: "\00a0";  /* Prevent margin collapse */
}


</style>
