<template>
    <div class="text-center">
        <button class="btn btn-primary float-center" @click="download" >Clica aquí per a descarregar</button>
    </div>
    
</template>
<script>
export default {
    name: "Iso",
    props: {
        record: Object
    },
    data() {
        return {
            iso: '',
        }
    },
    created() {
        this.fillIso();
        
    },
    methods: {
        fillIso: function() {
            let pos = 0;
            let content = '';
            let directory = '';
            for(let field of this.record.etiquetes) {
                if(field.etiqueta[0] !== '9') {
                    let fieldContent = '';

                    if (parseInt(field.etiqueta) > 8) {
                        fieldContent += field.ind1 + field.ind2;
                        if(field.subcamps.length) {
                            for(let subField of field.subcamps) {
                                fieldContent += String.fromCharCode(0x1f) + subField.codi + subField.contingut;
                            }
                        }
                    }
                    else
                        fieldContent += field.contingut;
                    
                    fieldContent += String.fromCharCode(0x1e);
                    directory += field.etiqueta + fieldContent.length.toString().padStart(4, '0') + pos.toString().padStart(5, '0');
                    pos += this.mbLength(fieldContent);
                    content += fieldContent;
                }
            }
            directory += String.fromCharCode(0x1e)
            content += String.fromCharCode(0x1d)
            this.iso = this.record.capsalera + directory;
            let length1 = this.iso.length.toString().padStart(5, '0');
            let length2 = ( this.iso.length + this.mbLength(content)).toString().padStart(5, '0');
            this.iso += content;
            this.iso = length2 + this.iso.substring(5, 12) + length1 + this.iso.substring(17);
        },
        mbLength: function(str) {
            var s = str.length;
            for (var i = str.length - 1; i >= 0; i--) {
                var code = str.charCodeAt(i);
                if (code > 0x7f && code <= 0x7ff) s++;
                else if (code > 0x7ff && code <= 0xffff) s+=2;
                if (code >= 0xDC00 && code <= 0xDFFF) i--; //trail surrogate
            }
            return s;
        },
        download: function() {
            let element = document.createElement('a');
            element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(this.iso));
            element.setAttribute('download', "iso2701.dat");

            element.style.display = 'none';
            document.body.appendChild(element);

            element.click();

            document.body.removeChild(element);
        }
    }
}
</script>