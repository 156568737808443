<template>
    <h1>Participants</h1>

<div class="content">

<br />
<a class="part" href="http://www.ateneubcn.org" target="_blank">Ateneu Barcelonès</a>
<a class="cat" href="http://www.ateneubcn.org/biblioteca-i-arxiu/cataleg">catàleg</a><br />

<a class="part" href="http://arxiu.escolapia.cat/" target="_blank">Arxiu Provincial de l'Escola Pia de Catalunya</a><br/>

<a class="part" href="http://www.bnc.cat/" target="_blank">Biblioteca de Catalunya</a>
<a class="cat" href="http://cataleg.bnc.cat/" target="_blank">catàleg</a><br />

<a class="part" href="http://www.casaasia.cat" target="_blank">Casa Àsia</a>
<a class="cat" href="http://beg.gencat.net/vtls/catalan/vtls-basic.html" target="_blank">catàleg</a><br />

<a class="part" href="http://dones.gencat.cat/ca/ambits/centre_documentacio/" target="_blank">Centre de Documentació Joaquima Alemany i Roca (Institut Català de les Dones)</a>
<a class="cat" href="http://dones.gencat.cat/.content/03_ambits/centre_documentacio/beg_icd_mat.html" target="_blank">catàleg</a><br />

<a class="part" href="http://www.vinseum.cat/?cat/Vinseum/Inici" target="_blank">Centre de Documentació VINSEUM (CDV)</a>
<a class="cat" href="http://www.vinseum.cat/?cat/Documentacio/Centre_de_Documentacio" target="_blank">catàleg</a><br />

<a class="part" href="http://www.centrelectura.org" target="_blank">Centre de Lectura (Reus)</a>
<a class="cat" href="http://79.148.245.217/uhtbin/cgisirsi.exe/x/CLR/0/49" target="_blank">catàleg</a><br />

<a class="part" href="http://www.arquitectes.cat" target="_blank">Col·legi d'Arquitectes de Catalunya</a>
<a class="cat" href="http://biblioteca.coac.net/uhtbin/cgisirsi.exe/nDhwDzbwfw/BARCELONA/71320017/60/502/X" target="_blank">catàleg</a><br />

<a class="part" href="http://www.csuc.cat" target="_blank">Consorci de Serveis Universitaris de Catalunya </a><a class="cat" href="http://ccuc.cbuc.cat/" target="_blank">CCUC</a><br />
<a class="part" href="http://www.filmoteca.cat" target="_blank">Filmoteca de Catalunya </a><a class="cat" href=" http://www.filmoteca.cat/web/catalegs" target="_blank">catàleg</a><br />

<a class="part" href="http://www.icab.es" target="_blank">Il·lustre Col·legis d'Advocats de Barcelona </a>
<a class="cat" href="http://biblio.icab.es/cgi-bin/abweb/X5102/" target="_blank">catàleg</a><br />

<a class="part" href="http://www.macba.cat" target="_blank">Museu d’Art Contemporani de Barcelona </a>
<a class="cat" href="http://pleiades.cbuc.cat/search~S4*cat" target="_blank">catàleg</a><br />

<a class="part" href="http://www.mnac.cat/index.jsp?lan=001" target="_blank">Museu Nacional d'Art de Catalunya </a>
<a class="cat" href="http://pleiades.cbuc.cat/search~S3*cat" target="_blank">catàleg</a><br />

<a class="part" href="http://www.parlament.cat/web" target="_blank">Parlament de Catalunya</a>
<a class="cat" href="http://www.parlament.cat/ABSYS/abwebp.cgi/G0">catàleg</a><br />

<a class="part" href="http://www.uab.es/" target="_blank">Universitat Autònoma de Barcelona</a>
<a class="cat" href="http://cataleg.uab.cat/" target="_blank">catàleg</a><br />

<a class="part" href="http://www.uda.ad/" target="_blank">Universitat d'Andorra</a>
<a class="cat" href="https://biblio.uda.ad/modules/login/main.php" target="_blank">catàleg</a><br />

<a class="part" href="http://www.ub.es/" target="_blank">Universitat de Barcelona</a>
<a class="cat" href="http://ub.cbuc.cat/" target="_blank">catàleg</a><br />

<a class="part" href="http://www.udg.es/" target="_blank">Universitat de Girona</a>
<a class="cat" href="http://udg.cbuc.cat/" target="_blank">catàleg</a><br />

<a class="part" href="http://www.udl.cat/" target="_blank">Universitat de Lleida</a>
<a class="cat" href="http://udl.cbuc.cat/*cat" target="_blank">catàleg</a><br />

<a class="part" href="http://www.uv.es/" target="_blank">Universitat de València</a>
<a class="cat" href="http://trobes.uv.es/" target="_blank">catàleg</a><br />

<a class="part" href="http://www.uvic.es/" target="_blank">Universitat de Vic</a>
<a class="cat" href="http://www.uvic.cat/biblioteca/cataleg/inici.html" target="_blank">catàleg</a><br />

<a class="part" href="http://www.uji.es/" target="_blank">Universitat Jaume I</a>
<a class="cat" href="http://sorry.uji.es/cd/opac/catalan/" target="_blank">catàleg</a><br />

<a class="part" href="http://www.uoc.edu/web/cat/index.html" target="_blank">Universitat Oberta de Catalunya</a>
<a class="cat" href="http://uoc.cbuc.cat/" target="_blank">catàleg</a><br />

<a class="part" href="http://www.upc.es/" target="_blank">Universitat Politècnica de Catalunya</a>
<a class="cat" href="http://upc.cbuc.cat/" target="_blank">catàleg</a><br />

<a class="part" href="http://www.upf.edu/" target="_blank">Universitat Pompeu Fabra</a>
<a class="cat" href="http://cataleg.upf.edu/" target="_blank">catàleg</a><br />

<a class="part" href="http://www.url.edu/" target="_blank">Universitat Ramon Llul</a>
<a class="cat" href="http://www.url.edu/serveis/biblioteques" target="_blank">catàleg</a><br />

<a class="part" href="http://www.urv.es/" target="_blank">Universitat Rovira i Virgili</a>
<a class="cat" href="http://cataleg.urv.cat/" target="_blank">catàleg</a>
</div>

</template>

<style scoped>
a, a:visited, a:hover {
    font-weight: bold;
    text-decoration: none;
    color: rgb(6, 40, 30);
}
.part {
    margin-right: 0.7em;
}
.cat, .cat:visited, .cat:hover {
    color: rgb(128,0,0);
}

.cat:before {
    content: "\1F4D1"
}
</style>