<template>
<div class="container">
    <div class="row">
        <div class="col-8">
            <div v-for="(item, key) in card" :key="key">
                <div v-if="item.content.length">
                    <span class="h5" v-if="item.header" v-html="item.header"></span><span style="display: inline" v-if="item.subheader">{{ item.subheader }}</span>
                    <ul v-for="(content, index) in item.content" :key="index">
                        <li>
                            <span v-if="item.label">
                                {{ item.label }}
                            </span>
                            <span v-html="content" :class="[key == 'subject' ? 'h5': '']"></span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="col-4" v-if="image.show || video.show || signature.show">
            <div class="row">
                <div class="col-12" v-if="image.show">
                    <img class="fitxa-img" :src="image.src" />
                    <div class="alert alert-light fitxa-img-peu" role="alert" v-html="image.foot" v-if="image.foot.length > 0"></div>
                </div>
                <div class="col-12" v-if="video.show">
                    <video controls :src="video.src" class="video">
                    </video>
                    <div class="alert alert-light fitxa-img-peu" role="alert" v-html="video.foot" v-if="video.foot.length > 0"></div>
                </div>
                <div class="col-12" v-if="signature.show">
                    <img class="fitxa-img" :src="signature.src" />
                    <div class="alert alert-light fitxa-img-peu" role="alert" v-html="signature.foot" v-if="signature.foot.length > 0"></div>
                </div>
            </div>
            
        </div>
    </div>
</div>
</template>
<script>
export default {
    name: 'Fitxa',
    props: {
        record: Object,
    },
    data() {
        return {
            card: {
                recordNumber: { header: null, label: 'Número de registre: ', content: [] },
                subject: { header: 'Encapçalament acceptat', label: null, content: [] },
                label4xx: { header: 'Emprat per', label: null, content: [] },
                label5xxrel: { header: 'Encapçalaments relacionats', label: null, content: [] },
                label5xxant: { header: 'Encapçalaments anteriors', label: null, content: [] },
                label5xxpos: { header: 'Encapçalaments posteriors', label: null, content: [] },
                label663: { header: 'Encapçalaments relacionats', label: null, content: [] },
                label024: { header: 'Identificadors', label: null, content: [] },
                label370c: { header: 'País associat', label: null, content: [] },
                label370f: { header: 'Lloc associat', label: null, content: [] },
                label046f: { header: 'Data de naixement', label: null, content: [] },
                label046g: { header: 'Data de mort', label: null, content: [] },
                label046k: { header: 'Data inicial o única de creació', label: null, content: [] },
                label046l: { header: 'Data final de creació', label: null, content: [] },
                label046o: { header: 'Data inicial o única per al contingut agregat', label: null, content: [] },
                label046p: { header: 'Data final per al contingut agregat', label: null, content: [] },
                label046q: { header: 'Data de creació', label: null, content: [] },
                label046r: { header: 'Data de cessament', label: null, content: [] },
                label046s: { header: "Data d'inici", label: null, content: [] },
                label046t: { header: "Data d'acabament", label: null, content: [] },
                label370a: { header: 'Lloc de naixement', label: null, content: [] },
                label370b: { header: 'Lloc de mort', label: null, content: [] },
                label370e: { header: 'Lloc de residència/seu', label: null, content: [] },
                label370g: { header: "Lloc d'origen", label: null, content: [] },
                label371: { header: 'Adreça', label: null, content: [], subheader: '' },
                label378q: { header: 'Forma completa del nom', label: null, content: [], subheader: '' },
                label336: { header: 'Tipus de contingut', label: null, content: [], subheader: '' },
                label368a: { header: "Tipus d'entitat corporativa", label: null, content: [], subheader: '' },
                label368b: { header: 'Tipus de jurisdicció', label: null, content: [], subheader: '' },
                label368c: { header: 'Atributs', label: null, content: [], subheader: '' },
                label368d: { header: 'Títol de la persona', label: null, content: [], subheader: '' },
                label372: { header: "Camp d'activitat", label: null, content: [], subheader: '' },
                label373: { header: "Afiliació", label: null, content: [], subheader: '' },
                label374: { header: 'Professió o ocupació', label: null, content: [], subheader: '' },
                label376a: { header: "Tipus de família", label: null, content: [], subheader: '' },
                label376b: { header: "Nom de membre prominent", label: null, content: [], subheader: '' },
                label376c: { header: 'Títol hereditari', label: null, content: [], subheader: '' },
                label380: { header: "Forma de l'obra", label: null, content: [], subheader: '' },
                label381: { header: "Altres característiques distintives", label: null, content: [], subheader: '' },
                label382: { header: "Instrumentació", label: null, content: [], subheader: '' },
                label383a: { header: "Número d'ordre", label: null, content: [], subheader: '' },
                label383b: { header: "Número d'opus", label: null, content: [], subheader: '' },
                label383c: { header: "Número d'índex temàtic", label: null, content: [], subheader: '' },
                label384: { header: "Tonalitat", label: null, content: [], subheader: '' },
                label643: { header: "Lloc i editor de la col·lecció", label: null, content: [], subheader: '' },
                label377: { header: "Llengua", label: null, content: [], subheader: '' },
                label678: { header: "Dades biogràfiques o històriques", label: null, content: [], subheader: '' },
                label667: { header: "Ús de l'encapçalament", label: null, content: [], subheader: '' },
                label670: { header: "Fonts", label: null, content: [], subheader: '' },
                label856: { header: "<br />", label: null, content: [], subheader: '' },
                label859: { header: "<br /", label: null, content: [], subheader: '' },
            },
            image: { show: false, src: '', foot: '' },
            video: { show: false, src: '', foot: '' },
            signature: { show: false, src: '', foot: '' }
        }
    },
    created() {
        this.cardFormat();
    },
    methods: {
        cardFormat: async function() {
            for(let etiqueta of this.record.etiquetes) {
                if (etiqueta.hidden) continue;
                if(etiqueta.etiqueta == '001')
                    this.card.recordNumber.content.push(etiqueta.contingut);
                
                if(etiqueta.etiqueta.startsWith('1')) {                    
                    let encap = '<strong>' + etiqueta.contingut + '</strong>';
                    this.card.subject.content.push(encap);
                }
                if(etiqueta.etiqueta.startsWith('4')) 
                    this.card.label4xx.content.push(etiqueta.contingut);
                if(etiqueta.etiqueta.startsWith('5')) {
                    let has_w = false;
                    let subs = [];
                    let addSubfield = false;
                    for (let s of etiqueta.subcamps) {
                        if (s.codi == 'a')
                            addSubfield = true;
                        if (addSubfield)
                            subs.push(s.contingut);
                        if (s.codi == 'w') {
                            has_w = s.contingut;
                        }
                    }
                    let textToFind = subs.join(" ");
                    switch (has_w) {
                        case 'r':
                            this.getLink(etiqueta.contingut.substring(2), textToFind, this.card.label5xxrel.content);
                            break;
                        case 'a':
                            this.getLink(etiqueta.contingut.substring(2), textToFind, this.card.label5xxant.content);
                            break;
                        case 'b':
                            this.getLink(etiqueta.contingut.substring(2), textToFind, this.card.label5xxpos.content);
                            break;
                        case false:
                            
                            this.getLink(etiqueta.contingut, textToFind, this.card.label5xxrel.content);
                            break;
                    }

                }
                if (etiqueta.etiqueta == '663') {
                    let content = '';
                    for (let s of etiqueta.subcamps) {
                        if (s.codi == 'a') {
                            content = s.contingut;
                        } else if (s.codi == 'b') {
                            content += " " + await this.getLinkAsync(s.contingut, s.contingut);
                        }
                    }
                    this.card.label663.content.push(content);
                }
                if (etiqueta.etiqueta == '024') {
                    let fontId = '';
                    let url = '';
                    let identificador = '';
                    let content = '';
                    for (let s of etiqueta.subcamps) {
                        if (s.codi == 'a') {
                            identificador = s.contingut;
                        } else if (s.codi == '2') {
                            fontId = s.contingut;
                        } else if(s.codi == '1') {
                            url = s.contingut;
                        } else if(s.codi == '0') {
                            url = s.contingut;
                        }
                    }
                    if (fontId.length) content += fontId + ": ";
                    if (url.length) content += `<a href="${url}" target="_blank">${identificador}</a>`;
                    else {
                        if (identificador.startsWith("http")) {
                            content += `<a href="${identificador}" target="_blank">${identificador}</a>`;
                        }
                        else {
                            content += identificador;
                        }
                    }
                    this.card.label024.content.push(content);
                }
                if (etiqueta.etiqueta == '370') {
                    for (let s of etiqueta.subcamps) {
                        switch (s.codi) {
                            case 'a':
                                this.card.label370a.content.push(s.contingut);
                                break;
                            case 'c':
                                this.card.label370c.content.push(s.contingut);
                                break;
                            case 'f':
                                this.card.label370f.content.push(s.contingut);
                                break;
                            case 'b':
                                this.card.label370b.content.push(s.contingut);
                                break;
                            case 'e':
                                this.card.label370e.content.push(s.contingut);
                                break;
                            case 'g':
                                this.card.label370g.content.push(s.contingut);
                                break;   
                        }
                    }
                }
                if (etiqueta.etiqueta == '046') {
                    for (let s of etiqueta.subcamps) {
                        switch (s.codi) {
                            case 'f':
                                this.card.label046f.content.push(s.contingut);
                                break;
                            case 'g':
                                this.card.label046g.content.push(s.contingut);
                                break;
                            case 'k':
                                this.card.label046k.content.push(s.contingut);
                                break;
                            case 'l':
                                this.card.label046l.content.push(s.contingut);
                                break;
                            case 'o':
                                this.card.label046o.content.push(s.contingut);
                                break;
                            case 'p':
                                this.card.label046p.content.push(s.contingut);
                                break;
                            case 'q':
                                this.card.label046q.content.push(s.contingut);
                                break;
                            case 'r':
                                this.card.label046r.content.push(s.contingut);
                                break;
                            case 's':
                                this.card.label046s.content.push(s.contingut);
                                break;
                            case 't':
                                this.card.label046t.content.push(s.contingut);
                                break;      
                        }
                    }
                }
                if (etiqueta.etiqueta == '371') {
                    for (let s of etiqueta.subcamps) {
                        switch (s.codi) {
                            case 'a':
                            case 'b':
                            case 'c':
                            case 'd':
                            case 'e':
                            case 'm':
                                this.card.label371.content.push(s.contingut);
                                break;
                            case 's':
                                this.card.label371.subheader += " " + s.contingut + "- ";
                                break;
                            case 't':
                                this.card.label371.subheader += s.contingut;
                                break;
                        }
                    }
                }
                if (etiqueta.etiqueta == '378') {
                   for (let s of etiqueta.subcamps) {
                        switch (s.codi) {
                            case 'q':
                                this.card.label378q.content.push(s.contingut);
                                break;
                        }
                   }
                }
                if (etiqueta.etiqueta == '336') {
                    for (let s of etiqueta.subcamps) {
                        if (s.codi == 'a') {
                            this.card.label336.content.push(s.contingut);
                        }
                    }
                }
                if (etiqueta.etiqueta == '368') {
                    let content = '';
                    for (let s of etiqueta.subcamps) {
                        switch (s.codi) {
                            case 'a':
                                this.card.label368a.content.push(s.contingut);
                                break;
                            case 'b':
                                this.card.label368b.content.push(s.contingut);
                                break;
                            case 'c':
                                this.card.label368c.content.push(s.contingut);
                                break;
                            case 'd':
                                content = s.contingut;
                                for (let s2 of etiqueta.subcamps) {
                                    if (s2.codi == 's')
                                        content += s2.contingut + "-";
                                    else if (s2.codi == 't') {
                                        content += s2.contingut;
                                    }
                                }
                                this.card.label368d.content.push(content);
                                break;
                        }
                    }
                }
                if (etiqueta.etiqueta == '372') {
                    let content_a = [];
                    let content_dates = '';
                    for (let s of etiqueta.subcamps) {
                        if (s.codi == 'a') {
                            content_a.push(s.contingut);
                        }
                    }
                    for (let s of etiqueta.subcamps) {
                        if (s.codi == 's') {
                          content_dates += ", " + s.contingut + "-";
                        }
                        if (s.codi == 't') {
                          
                          content_dates += s.contingut;
                        }
                    }
                    this.card.label372.content.push(content_a.join(". ") + content_dates);
                }
                if (etiqueta.etiqueta == '373') {
                    let content_a = [];
                    let content_dates = '';
                    for (let s of etiqueta.subcamps) {
                        if (s.codi == 'a') {
                            content_a.push(s.contingut);
                        }
                    }
                    for (let s of etiqueta.subcamps) {
                        if (s.codi == 's') {
                          content_dates += ", " + s.contingut + "-";
                        }
                        if (s.codi == 't') {
                          
                          content_dates += s.contingut;
                        }
                    }
                    this.card.label373.content.push(content_a.join(". ") + content_dates);
                }
                if (etiqueta.etiqueta == '374') {
                    let content_a = [];
                    let content_dates = '';
                    for (let s of etiqueta.subcamps) {
                        if (s.codi == 'a') {
                            content_a.push(s.contingut);
                        }
                    }
                    for (let s of etiqueta.subcamps) {
                        if (s.codi == 's') {
                          content_dates += ", " + s.contingut + "-";
                        }
                        if (s.codi == 't') {
                          
                          content_dates += s.contingut;
                        }
                    }
                    this.card.label374.content.push(content_a.join(". ") + content_dates);
                }
                if (etiqueta.etiqueta == '376') {
                    for (let s of etiqueta.subcamps) {
                        let content;
                        switch (s.codi) {
                            case 'a':
                                content = s.contingut;
                                for (let s2 of etiqueta.subcamps) {
                                    if (s2.codi == 's')
                                        content += s2.contingut + "-";
                                    else if (s2.codi == 't') {
                                        content += s2.contingut;
                                    }
                                }
                                this.card.label376a.content.push(content);
                                break;
                            case 'b':
                                this.card.label376b.content.push(s.contingut);
                                break;
                            case 'c':
                                content = s.contingut;
                                for (let s2 of etiqueta.subcamps) {
                                    if (s2.codi == 's')
                                        content += s2.contingut + "-";
                                    else if (s2.codi == 't') {
                                        content += s2.contingut;
                                    }
                                }
                                this.card.label376c.content.push(content);
                                break;
                        }
                    }
                }
                if (etiqueta.etiqueta == '380') {
                    for (let s of etiqueta.subcamps) {
                        if (s.codi == 'a') {
                            this.card.label380.content.push(s.contingut);
                        }
                    }
                }
                if (etiqueta.etiqueta == '381') {
                    for (let s of etiqueta.subcamps) {
                        if (s.codi == 'a') {
                            this.card.label381.content.push(s.contingut);
                        }
                    }
                }
                if (etiqueta.etiqueta == '382') {
                    let content = '';
                    for (let s of etiqueta.subcamps) {
                        switch (s.codi) {
                            case 'a':
                                content += (content.length ?  " " + s.contingut: s.contingut);
                                break;

                            case 'b':
                            case 'd':
                            case 'e':
                            case 'p':
                                content += " " + s.contingut;
                                break;
                            case 'n':
                                content += " (" + s.contingut + ")";
                                break;
                            case 'r':
                            case 's':
                            case 't':
                                content += " [Total: " + s.contingut + "]";
                                break;
                        }

                    }
                    this.card.label382.content.push(content);
                }
                if (etiqueta.etiqueta == '383') {
                    for (let s of etiqueta.subcamps) {
                        switch (s.codi) {
                            case 'a':
                                this.card.label383a.content.push(s.contingut);
                                break;
                            case 'b':
                                this.card.label383b.content.push(s.contingut);
                                break;
                            case 'c':
                                this.card.label383c.content.push(s.contingut);
                                break;
                        }
                    }
                }
                if (etiqueta.etiqueta == '384') {
                    for (let s of etiqueta.subcamps) {
                        if (s.codi == 'a') {
                            this.card.label384.content.push(s.contingut);
                        }
                    }
                }
                if (etiqueta.etiqueta == '643') {
                    let content = '';
                    for (let s of etiqueta.subcamps) {
                        if (s.codi == 'a') {
                            content += s.contingut;
                        } else if (s.codi == 'b' || s.codi == 'd') {
                            content += (", " + s.contingut);
                        }
                    }
                    this.card.label643.content.push(content);
                }
                if (etiqueta.etiqueta == '377') {
                    for (let s of etiqueta.subcamps) {
                        if (s.codi == 'l') {
                            this.card.label377.content.push(s.contingut);
                        }
                    }
                }
                if (etiqueta.etiqueta == '678') {
                    let content = '';
                    for (let s of etiqueta.subcamps) {
                        if (s.codi == 'a') {
                            content += s.contingut;
                        }
                        if (s.codi == 'u') {
                            content += `<a href="${s.contingut}" target="_blank">${s.contingut}</a>`;
                        }
                    }
                    this.card.label678.content.push(content);
                }
                if (etiqueta.etiqueta == '667') {
                    for (let s of etiqueta.subcamps) {
                        if (s.codi == 'a') {
                            this.card.label667.content.push(s.contingut);
                        }
                    }
                }
                if (etiqueta.etiqueta == '670') {
                    let content = '';
                    for (let s of etiqueta.subcamps) {
                        if (s.codi == 'a') {
                            content += s.contingut;
                        } else if (s.codi == 'b') {
                            content += (" " + s.contingut);
                        }
                    }
                    this.card.label670.content.push(content);
                }
                if (etiqueta.etiqueta == '856') {
                    let url = "", link_text="Informació relacionada";
                    let im = /^imatge*/i;
                    let vi = /^v(í|i)deo*/i;
                    let si = /^signatura*/i;
                    
                    for (let s of etiqueta.subcamps) {
                        if (s.codi == 'u') {
                            url = s.contingut.trim();
                        } else if (s.codi == 'y') {
                            link_text = s.contingut;
                            
                            if ( im.exec(link_text) /*link_text == 'Imatge Wikimedia Commons'*/) {
                                let parts = url.split('/');
                                if (parts.length) {

                                    this.image.foot = "Font: " + await this.getWikiImageMetadata(parts[parts.length - 1].trim());
                                }
                                
                                if (!this.image.show) {
                                    this.image.show = true;
                                    this.image.src = url;
                                }
                            } else if(vi.exec(link_text)) {
                                let parts = url.split('/');
                                
                                if (parts.length) {
                                    this.video.foot = "Font: " + await this.getWikiImageMetadata(parts[parts.length - 1].trim());
                                }
                                
                                if (!this.video.show) {
                                    this.video.show = true;
                                    this.video.src = url;
                                }


                            } else if(si.exec(link_text)) {
                                let parts = url.split('/');
                                if (parts.length) {
                                    this.signature.foot = "Font: " + await this.getWikiImageMetadata(parts[parts.length - 1].trim());
                                }
                                
                                if (!this.signature.show) {
                                    this.signature.show = true;
                                    this.signature.src = url;
                                }
                            }
                        }
                    }

                    let content = `<a href="${url}">${link_text}</a>`;
                    this.card.label856.content.push(content);
                }
                if (etiqueta.etiqueta == '859') {
                    let url = "", link_text="Imatge relacionada";
                    
                    for (let s of etiqueta.subcamps) {
                        if (s.codi == 'u') {
                            url = s.contingut;
                        } 
                    }

                    let content = `<a href="${url}">${link_text}</a>`;
                    this.card.label859.content.push(content);
                }

            }
        },
        getLink: function(text, searchText, labelContentArray) {
            let val = '';
            this.axios.get(`${process.env.VUE_APP_API_URL}/get_5xx_link/${searchText}`, { "headers": { "authorization": `bearer ${process.env.VUE_APP_API_KEY}` } })
                .then((res)=>{
                    val = `<a href="/detall/${res.data.data[0].id_registre}/0">${text}</a>`;
                })
                .catch(()=>{
                    val = text;
                })
                .finally(() => {
                    labelContentArray.push(val);
                });
        },
        getLinkAsync: async function(text, searchText) {
            let res = await this.axios.get(`${process.env.VUE_APP_API_URL}/get_5xx_link/${searchText}`, { "headers": { "authorization": `bearer ${process.env.VUE_APP_API_KEY}` } });
            if (res.request.status == 200)
                return `<a href="/detall/${res.data.data[0].id_registre}/0">${text}</a>`;
            else
                return text;
        },
        async getWikiImageMetadata(file) {
            let info = [];
            let discardedArtists = [
                'Desconegut', 'Desconocido/irrelevante',
                'Unknown author', 'No machine-readable author provided'
            ];
            let res = await this.axios.get(`${process.env.VUE_APP_API_URL}/wiki_metadata/${file}`, { "headers": { "authorization": `bearer ${process.env.VUE_APP_API_KEY}` } });
            if (res.data.data.extmetadata) {
                let includeAuthor = true;
                if (Object.keys(res.data.data.extmetadata).includes('Artist')) {
                    for(let banned of discardedArtists) {
                        if (res.data.data.extmetadata.Artist.value.includes(banned)) {
                            includeAuthor = false;
                            break;
                        }
                    }
                } else {
                    includeAuthor = false;
                }
                
                if (includeAuthor)
                    info.push(res.data.data.extmetadata.Artist.value);
                info.push(res.data.data.extmetadata.LicenseShortName.value);
                info.push('via Wikimedia Commons');
            }
            return info.join(', ');

        }
    }
}
</script>
<style scoped>
.fitxa-all {
    position: relative;
    z-index: 100;
}

.fitxa-img {
    width: 100%;
    max-width: 100%;
    border: solid 10px #fffff0;
    border-radius: 3px;
    filter: drop-shadow(10px 10px 10px #666666);
    
}

.fitxa-img-peu {
    max-width: 100%;
    margin-top: 1rem;
    font-size: .8rem;
    border: solid 1px #eee;
}

.video {
    width: 100%;
    border-radius: 3px;
    filter: drop-shadow(10px 10px 10px #666666);
}

</style>