<template>

<h1>Informació tècnica</h1>

<div id="bloc">
<p class="h3">Documents:</p>
<ul>
    <li><a href="/manual/index.html" target="_blank">Manual del Càntic (procediments de treball)</a> &nbsp;</li>
</ul>
<br />

<p class="h3">Informes</p>
<ul>
<li>Estivill Rius, Assumpció. <a href=http://www.recercat.cat/bitstream/2072/4571/1/0710InformeCantic.pdf>Informe sobre "El Càntic: procediments de treball / elaborat per la Biblioteca de Catalunya i pel Consorci de Biblioteques Universitàries de Catalunya". (2007)</a>&nbsp;<img border="0" src="../assets/pdf.png" /></li>
<li><a href="http://www.bnc.cat/sprof/normalitzacio/informes/stlacinforme.doc">Informe per a la creació de la llista d'autoritats de Catalunya de noms i títols: requeriments i funcionalitat.(2004)</a>&nbsp;<img border="0" src="../assets/word.png" /></li>
</ul>
<br />
<p class="h3">Sessions informatives</p>
<ul style="list-style-type:none">
<li><h4>CANTIC I</h4></li>

<li style="margin-left:50px">1) <a href="/files/cantic_ins_procediments_2015.pdf">Procediments de treball</a><br /></li>
<li style="margin-left:67px">Format MARC 21: <a href="files/cantic_008_2015.pdf">camp 008</a> i <a href="files/cantic_670_2015.pdf">camp 670 </a></li>
<li style="margin-left:50px">2) <a href="files/cantic_aacr2_22_2015.pdf">Encapçalaments de persones </a>(AACR2, capítols. 22 i 26) </li>

 
</ul>
<ul style="list-style-type:none">
<li style="list-style-type:none"><h4>CANTIC II</h4></li>

<li style="margin-left:50px">1) <a href="files/cantic_aacr2_23_2015.pdf">Noms geogràfics </a>(AACR2, capítols. 23 i 26) <br/></li>
<li style="margin-left:67px"><a href="files/geograficsmateries20104excorr.pdf">Encapçalaments de matèria: tractaments dels noms geogràfics</a></li>
<li style="margin-left:50px">2) <a href="files/cantic_accr2_24_2015.pdf">Encapçalaments de noms d’entitats </a> (AACR2 capítols. 24 i 26) </li>
</ul>
<ul style="list-style-type:none">
<li style="list-style-type:none"><h4>CANTIC III</h4></li>

<li style="margin-left:50px">1) <a href="files/cantic3_TUcasos_CCUC.pdf">Títols uniformes, casos especials </a>(AACR2, capítols. 25)</li>
<li style="margin-left:50px">2) <a href="files/ccuc_capitol25_titolsmusica.pdf">Títols uniformes de música </a> (AACR2 capítols. 25) </li>
</ul>
<p class="h3">Sessions informatives CANTIC-RDA</p>
<ul style="list-style-type:none">
<li style="list-style-type:none"><h4>CANTIC I-III</h4></li>
<li style="margin-left:67px">Format MARC 21: <a href="files/cantic_camp670_rda.pdf">camp 670 </a>[<a href="https://youtu.be/b_n4ILmNFY8" target="_blank">YouTube</a>]</li>
<li style="margin-left:67px"><a href="files/cantic_procediments_rda.pdf">Procediments de treball </a>[<a href="https://youtu.be/WR40T-CTE34" target="_blank">YouTube</a>]</li>
<li style="margin-left:50px">1) <a href="files/cantic_rda_obres_expressions.pdf">Obres i expressions (capítols 5 i 6) </a></li>
<li style="margin-left:50px">2) <a href="files/cantic_rda_persones.pdf">Descripció de persones (RDA capítols 8 i 9) </a>[YouTube <a href="https://youtu.be/s0XK7S3ySX0" target="_blank">part 1</a> i <a href="https://youtu.be/vM960znhlX8" target="_blank">part 2</a>]</li>
<li style="margin-left:50px">3) <a href="files/cantic_rda_families.pdf">Descripció de famílies (RDA capítols 8 i 10)</a></li>
<li style="margin-left:50px">4) <a href="files/cantic_rda_entitats.pdf">Descripció d’entitats corporatives (RDA capítols 8 i 11)</a></li>
<li style="margin-left:50px">5) <a href="files/cantic_rda_llocs.pdf">Identificació dels llocs (RDA capítol 16)</a></li>
<li style="margin-left:50px">6) <a href="files/cantic_rda_relacions.pdf">Relacions entre les persones, les famílies i les entitats corporatives (RDA capítols 29-32) </a></li>
</ul>
<h3>Sessions informatives CANTIC-ALMA</h3>
<ul style="list-style-type:none">
<li style="margin-left:50px">1) <a href="/video/alma_autoritats/ALMA%20i%20les%20autoritats%3A%20qu%C3%A8%20cal%20saber">ALMA i les autoritats: què cal saber </a>(juny 2021)</li>
<li style="margin-left:50px">2) <a href="/video/alma_cerca/Cerca%20de%20les%20autoritats%20C%C3%80NTIC">Cerca de les autoritats CÀNTIC </a>(juny 2021)</li>
<li style="margin-left:50px">3) <a href="/video/alma_creacio/Creaci%C3%B3%20d%E2%80%99un%20registre%20C%C3%80NTIC">Creació d’un registre CÀNTIC </a>(juny 2021)</li>
</ul>
<h3>CANTIC essencial (gener 2023)</h3>
<ul>
    <li style="margin-left:50px"><a href="files/Cantic_essencial2023part1.pdf">Part 1</a></li>
    <li style="margin-left:50px"><a href="files/Cantic_essencial2023part2.pdf">Part 2</a></li>
</ul>
</div>

</template>