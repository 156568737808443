<template>
<div class="content">

    <h1>Accessibilitat web</h1>
    


    <div class="attribute-long">
                
<p>La Biblioteca de Catalunya s’ha compromès a fer accessibles els seus webs de conformitat amb el Reial Decret 1112/2018, de 7 de setembre, sobre accessibilitat dels llocs web i aplicacions per a dispositius mòbils del sector públic (en endavant, Reial Decret 1112/2018, de 7 de setembre).</p><p>Aquesta declaració d’accessibilitat s’aplica al web http://cantic.bnc.cat</p><a name="eztoc1761732_0_1" id="eztoc1761732_0_1"></a><h3>Situació de compliment</h3><p>Aquest web és <b>parcialment conforme</b> amb el Reial &nbsp;Decret 1112/2018, de 7 de setembre, a causa de les excepcions i la &nbsp;manca de conformitat dels aspectes que s’indiquen a continuació.</p><a name="eztoc1761732_0_2" id="eztoc1761732_0_2"></a><h3>Contingut no accessible</h3><p>El contingut que es recull a continuació no és accessible pels següents motius:</p>
<ul>

<li>
Manca de conformitat amb el Reial Decret 1112/2018, de 7 de setembre: &nbsp;<br> 
<ul>

<li>Podrien existir diversos errors d’edició en alguna pàgina web, tant a continguts HTML com a documents finals, publicats amb data posterior al 20 de setembre de 2018. </li>

<li>És necessari fer ús de doble scroll per a la visualització del contingut complet en dispositius amb àrees de visualització reduïda o per a zooms a partir de 300%.</li>

<li>No es proporcionen subtítols sincronitzats per al contingut multimèdia en directe ni per a una part del contingut multimèdia gravat.</li>

<li>"La BC en xifres": Taules de dades complexes i sense encapçalaments.</li>

</ul>
</li>

<li>Càrrega desproporcionada: no aplica.</li>

<li>El contingut no entra dins de l’àmbit de la legislació aplicable: podrien existir arxius ofimàtics en PDF o altres formats publicats abans del 20 de setembre de 2018 que no compleixin en la totalitat els requisits d’accessibilitat.</li>

</ul>
<a name="eztoc1761732_0_3" id="eztoc1761732_0_3"></a><h3>Preparació de la present declaració d’accessibilitat</h3><p>La present declaració va ser preparada el 18 de setembre de 2020.</p><p>El mètode utilitzat per preparar la declaració ha estat una autoevaluació portada a terme per la Biblioteca de Catalunya.</p><p>Darrera revisió de la declaració: 18 de setembre de 2020.</p><a name="eztoc1761732_0_4" id="eztoc1761732_0_4"></a><h3>Observacions i dades de contacte</h3><a name="eztoc1761732_0_4_1" id="eztoc1761732_0_4_1"></a><h4>Comunicacions</h4><p>Es poden realitzar comunicacions sobre requisits d’accessibilitat (article 10.2.a del Reial Decret 1112/2018), a través de la següent <a href="https://ovt.gencat.cat/gsitfc/AppJava/generic/conqxsGeneric.do?topicLevel1.id=500&amp;topicLevel2.id=20523&amp;webFormId=391&amp;set-locale=ca_ES" target="_blank">bústia de contacte</a>.</p><p>Per exemple:</p>
<ul>

<li>Informar sobre qualsevol possible incompliment per part d’aquest lloc web.</li>

<li>Transmetre altres dificultats d’accés al contingut.</li>

<li>Formular qualsevol altra consulta o suggeriment de millora relativa a l’accessibilitat del lloc web.</li>

</ul>
<a name="eztoc1761732_0_4_2" id="eztoc1761732_0_4_2"></a><h4>Sol·licituds d’informació accessible i queixes</h4><p>Per fer sol·licituds d’informació accessible i queixes (article 12 del Reial Decret 1112/2018) cal accedir al tràmit “<a href="https://web.gencat.cat/ca/tramits/tramits-temes/Continguts-accessibles-al-web" target="_blank">Continguts accessibles al web</a>” i seguir la modalitat “<a href="https://web.gencat.cat/ca/tramits/tramits-temes/Continguts-accessibles-al-web?moda=1" target="_blank">Sol•licitar informació accessible o fer una queixa</a>”.</p><p>Qualsevol persona física o jurídica pot formular queixes relatives al compliment dels requisits del Reial Decret 1112/2018 i sol·licitar informació relativa a continguts que estiguin exclosos de l’àmbit d’aplicació d’aquesta normativa.</p><p>Les sol·licituds i queixes es registren conforme als requisits establerts per la Llei 39/2015 d’1 d’octubre, del Procediment Administratiu Comú de les Administracions Públiques.</p><a name="eztoc1761732_0_4_3" id="eztoc1761732_0_4_3"></a><h4>Procediment d’aplicació</h4><p>El procediment d’aplicació recollit a l’article 13 del Reial Decret 1112/2018 es pot iniciar al tràmit “<a href="https://web.gencat.cat/ca/tramits/tramits-temes/Continguts-accessibles-al-web" target="_blank">Continguts accessibles al web</a>” a través de la modalitat “<a href="https://web.gencat.cat/ca/tramits/tramits-temes/Continguts-accessibles-al-web?category=&amp;moda=2" target="_blank">Reclamar una sol·licitud</a>”.</p><p>Si un cop realitzada una sol•licitud d’informació accessible o queixa, aquesta ha estat desestimada, no estàs d’acord amb la decisió adoptada o la resposta no compleix amb els requisits de l’article 12.5 del RD 1112/2018, pots iniciar una reclamació per</p>
<ul>

<li>conèixer i oposar-te als motius de la desestimació</li>

<li>instar a l’adopció de les mesures oportunes en cas de no estar d’acord amb la decisió adoptada</li>

<li>exposar les raons per les quals consideres que la resposta no compleix amb els requisits exigits.</li>

</ul>
<p>També es pot iniciar una reclamació en cas que hagin transcorregut 20 dies hàbils sense haver obtingut cap resposta.</p><a name="eztoc1761732_0_5" id="eztoc1761732_0_5"></a><h3>El compromís de la biblioteca per l'accessibilitat</h3><p>La Biblioteca de Catalunya treballa amb l'objectiu d'adaptar tot el web a les recomanacions del Consorci World Wide Web W3C per tal d'eliminar les barreres que dificulten l'accés a la informació i la comunicació. Aquestes recomanacions es concreten en les pautes d'accessibilitat del web WCAG 2.1.</p><p>El compromís de la Biblioteca de Catalunya és arribar a complir el nivell de conformitat ‘Doble-A’ (AA), tal com exigeixen a les administracions públiques les legislacions europea i estatal.</p><p>La Directiva (UE) 2016/2102 del Parlament Europeu i del Consell de 26 d’octubre de 2016 estableix les normes que s’exigeixen als estats membres per tal que tots els webs i aplicacions mòbils del sector públic, independentment del dispositiu utilitzat per accedir-hi, compleixin els requisits d’accessibilitat que es recullen a la norma EN 301 549 v2.1.2 (d’agost de 2018), de requisits d’accessibilitat adequats per a la contractació pública de productes i serveis TIC a Europa.</p><p>La norma EN 301 549 v2.1.1.2 es basa principalment en les Web Content Accessibility Guidelines (WCAG) 2.1, d’agost de 2018. Concretament cal complir el nivell AA. No obstant això, la norma també té altres requisits addicionals, obligatoris i condicionals.</p><p>La directiva és d’obligat compliment però no és directament aplicable, per això en l'àmbit estatal s’ha desenvolupat un real decret que desenvolupa l’abast i els requisits, com a mínim. La transposició estatal és el Reial Decret 1112/2018, de 7 de setembre, sobre accessibilitat dels llocs webs i aplicacions per a dispositius mòbils del sector públic.</p>            </div>
        
                        
    </div>
</template>