<template>
<div class="content">
    <p class="h3">{{ $route.params.title }}</p>
    
    <video controls autoplay>
        <source :src="'/movies/'+$route.params.video + '.mp4'" type="video/mp4">
        Aquest navegador no suportat la etiqueta: "video"
    </video>

    <button class="btn btn-primary"><a href="/infotec">Tornar</a></button>
</div>
</template>
<style scoped>
p.h3 {
    margin-top: 1em;
    margin-bottom: 1em;
    text-align: center;
    margin-bottom: 1em;
}
video {
    width: 100%;
}
button {
    margin: 0 auto;
    display: block;
    
}
a {
    color: white !important;
    text-decoration: none;
}
</style>