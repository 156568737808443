<template>
  <Capcalera />
  <div class="container"><router-view/></div>
  <Peu />
</template>

<script>
import Capcalera from './components/Capcalera'
import Peu from './components/Peu'

export default {
  components: { Capcalera, Peu },
  setup() {
    
  },
}
</script>

