<template>
    <div class="alert alert-warning" role="alert">
        <h1>No hem trobat aquest registre</h1>
        <p class="h3">
            Repaseu si heu posat el número de registre correctament. Si el
            número és correcte espereu una estona i torneu a provar, que
            alguna cosa no va bé entre bastidors &#x1F631;
        </p>
    </div>
</template>
<style scoped>
.alert {
    margin-top: 2em;
}
</style>