<template>
    <table class="table table-bordered table-responsive-md">
        <thead class="thead-dark">
            <tr>
                <th style="with: 8.33%">Etiqueta</th>
                <th style="with: 4.17%">Ind. 1</th>
                <th style="with: 4.17%">Ind. 2</th>
                <th style="with: 83.33%">Contingut</th>
            </tr>
        </thead>
        <tbody>
            <tr>
                <td class="bg-secondary text-white" colspan="3">Capçalera</td>
                <td>{{record.capsalera}}</td>
            </tr>

            <tr v-for="etiqueta in visibleFields" :key="etiqueta.id">
                <td style="width: 8.33%" class="bg-secondary text-white">{{etiqueta.etiqueta}}</td>
                <td style="width: 4.17%" class="bg-light">{{etiqueta.ind1}}</td>
                <td style="width: 4.17%" class="bg-light">{{etiqueta.ind2}}</td>
                <td style="width: 83.33%" v-html="getMarc21(etiqueta)"></td>
            </tr>
        </tbody>
    </table>
</template>

<script>
export default {
    name: 'Marc',
    props: {
        record: Object
    },
    methods: {
        getMarc21: function(etiqueta) {
            if(parseInt(etiqueta.etiqueta) < 10)
                return etiqueta.contingut;
            let content = "";
            let first = true;
            for(let subcamp of etiqueta.subcamps) {
                if(first && subcamp.codi == 'a'){
                    content += subcamp.contingut;
                } else {
                    content += '<b>$' + subcamp.codi + '</b>' + subcamp.contingut;
                }
                first = false;
            }
            return content;
        }
    },
    computed: {
        visibleFields: function () {
            return this.record.etiquetes.filter(function (etiqueta) {
                return etiqueta.etiqueta[0] != 9;
            })
        }
    }
}
</script>

<style scoped>
.bg-secondary {
    background-color: #146c43 !important;
}

.bg-light {
    background-color: #d1e7dd !important;
}
</style>