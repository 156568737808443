<template>
    <nav class="capcalera navbar navbar-expand-lg navbar-dark bg-dark">
    <a class="navbar-brand" href="#"><img src="../assets/cantic.svg" class="logo" /></a>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarToggler" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="menu collapse navbar-collapse" id="navbarToggler">
      <ul class="navbar-nav ml-auto mt-2 mt-lg-0">
        <li class="nav-item" :class="$route.name == 'index' ? 'active': ''">
          <a class="nav-link" href="/">Cerca</a>
        </li>
        <li class="nav-item dropdown"  :class="['quees', 'infotec', 'pmf', 'protec', 'access'].includes($route.name) ? 'active' : ''">
          <a class="nav-link dropdown-toggle" href="#" role="button" id="menuInformacio" data-bs-toggle="dropdown" aria-expanded="false">Informació</a>
          <ul class="dropdown-menu dropdown-menu-dark" aria-labelledby="menuInformacio">
            <li><a class="dropdown-item" href="/quees">Què és</a></li>
            <li><a class="dropdown-item" href="/infotec">Informació tècnica</a></li>
            <li><a class="dropdown-item" href="/pmf">Preguntes freqüents</a></li>
            <div class="dropdown-divider"></div>
            <li><a class="dropdown-item" href="/protec">Protecció de dades</a></li>
            <li><a class="dropdown-item" href="/access">Accesibilitat</a></li>
          </ul>
        </li>
        <li class="nav-item dropdown" :class="['participants', 'esdevenir', 'enviat'].includes($route.name) ? 'active': ''">
          <a class="nav-link dropdown-toggle" href="#" role="button" id="menuParticipants" data-bs-toggle="dropdown" aria-expanded="false">Participants</a>
          <ul class="dropdown-menu dropdown-menu-dark" aria-labelledby="menuParticipants">
            <li><a class="dropdown-item" href="/participants">Llistat de participants</a></li>
            <li><a class="dropdown-item" href="/esdevenir">Com esdevenir participant</a></li>
          </ul>
        </li>
        <li class="nav-item" :class="$route.name == 'altrescat' ? 'active': ''"><a class="nav-link" href="/altrescat">Altres catàlegs d'autoritat</a></li>
      </ul>
    </div>
  </nav>
</template>

<script>

export default {
  name: 'Capcalera',
}
</script>
<style scoped>
.capcalera {
  padding: 10px 15px 10px 15px;
}
.menu {
  margin-left: 30px;
}

.logo {
  height: 100px;
}
.active a {
  font-weight: 500;
  color: #fff3cd !important;
}
</style>